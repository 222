import React, { FC } from 'react';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Row, Column, Button } from '@nimles/react-web-components';
import { InputField } from '@nimles/react-web-forms';
import { UserModel } from '@nimles/models/lib/identity';
import { OrganizationModel } from '@nimles/models/lib/organization-management';

interface Props {
  user?: UserModel;
  organization?: OrganizationModel;
  onSubmit: (user: UserModel, organization: OrganizationModel) => void;
}

export const RegisterOrganizationForm: FC<Props> = ({
  onSubmit,
  user,
  organization,
}) => {
  const values = {
    user: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      username: user?.username || '',
    },
    organization: {
      name: organization?.name || '',
      corporateIdentity: organization?.corporateIdentity || '',
      email: organization?.email || '',
      phoneNumber: organization?.phoneNumber || '',
    },
  };

  const { t } = useTranslation();

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<FormikValues>
  ) => {
    try {
      onSubmit(values.user, values.organization);
      setSubmitting(false);
      setStatus({ success: true });
    } catch (error) {
      setStatus({ success: false });
      setSubmitting(false);
      setErrors({ submit: error.message });
    }
  };

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      render={({
        values,
        handleSubmit,
        isSubmitting,
        isValid,
        handleReset,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          {!user?.id ? (
            <fieldset>
              <legend>User</legend>
              <Row wrap="wrap">
                <Column xs={100} sm={50}>
                  <InputField
                    required
                    name="user.firstName"
                    label={t('prop.firstName')}
                    placeholder={t('prop.firstName')}
                  />
                </Column>
                <Column xs={100} sm={50}>
                  <InputField
                    name="user.lastName"
                    label={t('prop.lastName')}
                    placeholder={t('prop.lastName')}
                  />
                </Column>
              </Row>
              <InputField
                name="user.username"
                label={t('prop.email')}
                placeholder={t('prop.email')}
              />
              <InputField
                name="user.password"
                label={t('prop.password')}
                placeholder={t('prop.password')}
                type="password"
              />
            </fieldset>
          ) : null}
          <fieldset>
            {!user?.id ? <legend>Company</legend> : null}
            <Row wrap="wrap">
              <Column xs={100} sm={50}>
                <InputField
                  required
                  name="organization.name"
                  label={t('prop.organizationName')}
                  placeholder={t('prop.organizationName')}
                />
              </Column>
              <Column xs={100} sm={50}>
                <InputField
                  name="organization.corporateIdentity"
                  label={t('prop.corporateIdentity')}
                  placeholder={t('prop.corporateIdentity')}
                />
              </Column>
            </Row>
            <Row wrap="wrap">
              <Column xs={100} sm={50}>
                <InputField
                  required
                  name="organization.email"
                  label={t('prop.email')}
                  placeholder={t('prop.email')}
                />
              </Column>
              <Column xs={100} sm={50}>
                <InputField
                  name="organization.phoneNumber"
                  label={t('prop.phoneNumber')}
                  placeholder={t('prop.phoneNumber')}
                />
              </Column>
            </Row>
          </fieldset>
          <Row justify="flex-end" margin="10px -15px 0">
            <Column>
              <Button raised primary type="submit">
                {t(isSubmitting ? 'action.registering' : 'action.register')}
              </Button>
            </Column>
          </Row>
        </form>
      )}
    />
  );
};
